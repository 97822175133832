import React from 'react';
import Header from './components/header'
import Footer from './components/footer'
import Routing from './components/routing'
import { Provider as NavigationProvider } from './provider/navigation'
import { Provider as ContentProvider } from './provider/content'

const App = () => {
  return (
    <ContentProvider>
      <NavigationProvider>
        <div className="App">
          <Header />  
          <Routing />
          <Footer />
        </div>
      </NavigationProvider>
    </ContentProvider>
  );
}

export default App;
