import { Link } from "@reach/router"
import React from "react"
import AidsCenterLogo from "../../assets/aids-center-logo.png"
import GrantyMskLogo from "../../assets/granty-msk-logo.png"
import KomitetLogo from "../../assets/komitet-logo.png"
import VeraLogo from "../../assets/vera-logo.svg"
import { Context as ContentContext } from "../../provider/content"
import Navigation, { MobileNavigation } from "../navigation"

import "./header.scss"

const Header = () => {
  const { settings: { school: { date } = {} } = {} } =
    React.useContext(ContentContext);

  const Logo = (
    <Link to="/">
      <img src={VeraLogo} alt="Школа молодого врача" />
    </Link>
  );

  return (
    <div className="header">
      <div className="header-title bold">
        <div className="header-link">
          <Link to="/">Школа молодого врача</Link>
          <a className="header-img-link" href="https://spid.center">
            <img
              className="header-other-logo"
              src={AidsCenterLogo}
              alt="AidsCenter Logo"
            />
          </a>
        </div>
        {Logo}
        <div className="header-link registration">
          <Link aria-disabled="true" to="/registration">
            Подать заявку
          </Link>
          <div className="header-other-logo granty">
            <img
                src={KomitetLogo}
                className="komitet-img"
                alt="Комитет общественных связей и молодежной политики города Москвы"
              />
              <img
                src={GrantyMskLogo}
                className="granty-msk-img"
                alt="Гранты Мэра Москвы для социально ориентированных некоммерческих организаций"
              />
          </div>
        </div>
      </div>
      <Navigation />
      <MobileNavigation logo={Logo} />
    </div>
  );
};

export default Header;
