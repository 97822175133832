import React from 'react'
import GoogleMap from 'google-map-react';
import { Context as NavigationContext } from '../../provider/navigation'
import { Context as ContentContext } from '../../provider/content'
import FeedBackForm from '../../components/feedbackForm'
import Icons from '../../components/icons'
import ContactsPhoto from '../../assets/backgrounds/contacts.jpg'
import MarkerIcon from '../../assets/icons/map-marker.png'

import "./contacts.scss"

const Marker = ({ lat, lng }) => (
  <div className="map-marker" lat={lat} lng={lng}>
    <img src={MarkerIcon} alt=""/>
  </div>
)

const ContactsPage = () => {

  const { setBackground, setTheme } = React.useContext(NavigationContext)
  const { settings } = React.useContext(ContentContext)

  const { contacts = {} } = (settings || {})

  const [lat, lng] = (contacts.location || '').split(';').map(Number);

  setTheme('transparent')
  setBackground(ContactsPhoto)

  return (
    <div className="contacts">
      
      <div className="row">
        <h1>Контакты</h1>
      </div>

      <div className="row contacts-info">
        <div className="phone">
          <img src={Icons.PhoneIcon} alt=""/>
          <p>{contacts.phone}</p>
        </div>

        <div className="email">
          <img src={Icons.EmailIcon} alt=""/>
          <p>{contacts.email}</p>
        </div>

        <div className="address">
          <img src={Icons.AddressIcon} alt=""/>
          <p>{contacts.address}</p>
        </div>
      </div>

      {contacts.location && (
        <div className="row contacts-map">
          <GoogleMap
            bootstrapURLKeys={{
              key: 'AIzaSyAL5mENbZCcAskXSE55gHhn1F1kdzcaI3w'
            }}
            options={{scrollwheel: false}}
            center={{ lat, lng }}
            zoom={13}
          >
            <Marker lat={lat} lng={lng} />
          </GoogleMap>
        </div>
      )}

      <div className="row contacts-feedback">
        <FeedBackForm/>
      </div>
    </div>
  );
}

export default ContactsPage