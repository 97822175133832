import React from 'react'
import './blockquote.scss'

const Blockquote = ({ text, authorName, authorDesc, authorPhoto }) => {
  return (
    <blockquote className="blockquote">
      <div className="row blockquote-row">
        <div className="icon"></div>
        <div className="content">
          <div className="blockquote-text">
            {text}
          </div>
          <div className="blockquote-author">
            <img src={authorPhoto}/>
            <div>
              {authorName}
              <div className="blockquote-author-desc">{authorDesc}</div>
            </div>
          </div>
        </div>
      </div>
    </blockquote>
  )
}

export default Blockquote