import React from 'react'
import { Button } from '../../components/button'
import { Context as ContentContext  } from '../../provider/content'

import './form.scss'

const Form = () => {

  const { settings: { token } = {} } = React.useContext(ContentContext)

  const formRef = React.createRef()

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(event.target.name.value)

    const feedbackData = {
      name: event.target.name.value,
      email: event.target.email.value,
      message: event.target.question.value,
    }

    fetch('https://api.vera.school/feedback', {
      method: 'POST',
      headers: {
          'X-CSRF-Token': token,
          'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify({
          feedback: feedbackData
      })
    })
    .then(res => {
        return res.json()
    })
    .then(data => {
        console.log(data)
    })
    .catch(err => console.error(err))
  }

  return (
    <div>
      <h1>Форма обратной связи</h1>
      <form className="feedback-form" ref={formRef} onSubmit={handleSubmit}>

        <div className="form-field">
          <label htmlFor="name">Имя</label>
          <input type="text" name="name" id="name" placeholder="Мой ответ" required />
        </div>
        
        <div className="form-field">
          <label htmlFor="email">Почта</label>
          <input type="email" name="email" id="email" placeholder="Мой ответ" required />
        </div>
        
        <div className="form-field">
          <label htmlFor="question">Задайте вопрос</label>
          <input type="text" name="question" id="question" placeholder="Мой ответ" required />
        </div>

        {/* <Button onClick={handleCLick}>Отправить</Button> */}
        <Button type="submit">Отправить</Button>

      </form>
    </div>
  )
}

export default Form