import React from 'react'
import { Context as NavigationContext } from '../../provider/navigation'
import { Context as ContentContext } from '../../provider/content'
import { Link } from '@reach/router'
import SchoolPhoto from '../../assets/backgrounds/school.jpg'

import "./school.scss"

const INACTIVE = false;

const SchoolVideo = props => {
  const { course, youtube } = props
    console.log(youtube)
  return (
    <div className="school-videos-video" key={course.id}>
      <div className="school-videos-video-item">
        <iframe
          width="980"
          height="500"
          src={`https://www.youtube.com/embed/${youtube}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  )
}

const SchoolPage = () => {
  
  const { setBackground, setTheme } = React.useContext(NavigationContext)
  const { lessons } = React.useContext(ContentContext)
  
  setTheme('transparent')
  setBackground(SchoolPhoto)

  return (
    <div className="school">
      <div className="row">
        {INACTIVE ? (
          <h1>Coming soon</h1>
        ) : (
          <>
            <h1>Онлайн-школа</h1>
            <div className="school-videos">

              {lessons && lessons.map(lesson => {
                return (
                  <SchoolVideo {...lesson}/>
                )
              })}

            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SchoolPage