import React from 'react'
import { Router } from "@reach/router";
import { 
  AboutPage,
  ContactsPage,
  FeedbackPage,
  FeedbacksPage,
  FormFilledPage,
  LessonPage,
  MainPage,
  NotFound,
  ParticipantsPage,
  RegistrationPage,
  SchoolPage,
  SpeakersPage,
  SpeakerPage
} from '../page'

const Routing = () => {
  return (
    <Router primary={false}>
      <AboutPage path='/' />
      <ContactsPage path='/contacts' />
      <FeedbacksPage path='/feedbacks' />
      <FeedbackPage path='/feedbacks/:id' />
      <FormFilledPage path='/form-filled' />
      <LessonPage path='/lesson' />
      {/* <MainPage path='/' /> */}
      <ParticipantsPage path='/participants' />
      <RegistrationPage path='/registration' />
      <SchoolPage path='/school' />
      <SpeakersPage path='/speakers' />
      <SpeakerPage path='/speaker/:id' />
      <NotFound default />
    </Router>
  )
}

export default Routing