import React from "react";
import { Context as NavigationContext } from "../../provider/navigation";
import Blockquote from "../../components/blockquote";
import { LinkButton } from "../../components/button";
import List from "../../components/list";
import ParticipantsPhoto from "../../assets/backgrounds/participants.jpg";
import AuthorEremin from "../../assets/author-eremin.png";

import "./participants.scss";

const featuresListBlocks = [
  `Обучение в школе бесплатное. Мы организуем учебные материалы, двухразовое питание,
    7 дней проживания в отеле около места занятий и сувениры. Вам останется только оплатить дорогу до Москвы и обратно.`,
  `Приглашаем медицинских студентов, ординаторов и молодых врачей из ВУЗов России и стран СНГ. Врачебная специальность не важна.`,
  `В школе выступят врачи, юристы, представители сообществ и некоммерческих организаций, ВИЧ-активисты.
    Участники узнают базовую информацию про ВИЧ-инфекции и социально-правовые аспекты.`,
  `В первую очередь мы обращаем внимание на личную мотивацию участников.
    Еще посмотрим на резюме и учебный, научный и волонтерский опыт во всех сферах.`,
  `Поможем решить проблему с пропуском занятий — подготовим официальное приглашение для ВУЗа.`,
];

const ParticipantsPage = () => {
  const { setBackground, setTheme } = React.useContext(NavigationContext);

  setTheme("transparent");
  setBackground(ParticipantsPhoto);

  return (
    <div className="participants">
      <div className="row lead title">
        <h1>Участникам</h1>
        <p>
          Совсем скоро состоится новый отбор на школу, а пока ознакомьтесь с
          ключевыми особенностями нашего проекта.
        </p>
      </div>

      <div className="">
        <Blockquote
          text="Подавайте заявку и приглашайте друзей и коллег. Для нас важны ваша личная мотивация и интерес!"
          authorName="Антон Еремин"
          authorDesc='Фонд "СПИД.ЦЕНТР"'
          authorPhoto={AuthorEremin}
        />
      </div>

      <div className="row">
        <List blocks={featuresListBlocks} />
      </div>

      {/* enable when open
            <div className="row participants-registration">
        <LinkButton to="/registration">Подать заявку на участие</LinkButton>
      </div>
      */}
    </div>
  );
};

export default ParticipantsPage;
